import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
    Nav,
    Navbar,
    NavItem,
    NavLink
} from 'reactstrap';

class Footer extends Component {

    render = () => {
        return (
            <footer className='footer'>
                <Navbar dark expand='lg'>
                    <Nav className='mr-auto ml-5' navbar>
                        <NavItem className='ml-5'>
                            <NavLink tag={Link} to={`${process.env.PUBLIC_URL}/impressum`}>Impressum</NavLink>
                        </NavItem>
                    </Nav>
                    <Nav className="ml-auto mr-5" navbar>
                        <NavItem id='copyright'>
                            © Fabian Vieten 2020
                        </NavItem>
                    </Nav>
                </Navbar>
            </footer>
        );
    }
}

export default Footer;
