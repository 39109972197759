import React, { Component } from 'react';

class Skill extends Component {

    render = () => {
        return (
            <div className='skill'
                style={{
                    background: this.props.background,
                    color: this.props.color
                }}>

                {this.props.skill}
            </div>
        );
    }
}

export default Skill;