import React, { Component } from 'react';
import { Row } from 'reactstrap';
import Skill from './Skill';

class Skills extends Component {

    render = () => {
        return (
            <div className='smalltext smallslide'>
                <h2>KENNTNISSE</h2>
                <Row className='mt-5 ml-1'>
                    <Skill skill='JavaScript' background='#f7df1e' color='black' />
                    <Skill skill='React' background='#61dafb' color='black' />
                    <Skill skill='NodeJS' background='#026e00' color='white' />
                    <Skill skill='HTML' background='#eb4a2e' color='white' />
                    <Skill skill='CSS' background='#314ddc' color='white' />
                    <Skill skill='Bootstrap' background='#8251ad' color='white' />
                    <Skill skill='PHP' background='#8892BF' color='black' />
                    <Skill skill='Symfony' background='black' color='white' />
                    <Skill skill='Java' background='#f89917' color='black' />
                    <Skill skill='MySQL' background='#4479a1' color='white' />
                    <Skill skill='Docker' background='#0073EC' color='white' />
                    <Skill skill='Git' background='#f05134' color='white' />
                    <Skill skill='CI / CD' background='#2f2a6c' color='white' />
                    <Skill skill='Linux CLI' background='#0a3c61' color='white' />
                    <Skill skill='Scrum' background='#455868' color='white' />
                </Row>
            </div>
        );
    }
}

export default Skills;