import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Projects extends Component {

    render = () => {
        return (
            <div className='slides pb-5 pb-lg-0'>
                <h2>PROJEKTE</h2>
                <Row className='smallertext mt-5'>
                    <Col xs={12} lg={4} className='pr-lg-5'>
                        <a target='_blank' className='projectImg' rel='noopener noreferrer'
                            href='https://www.nytimes.com/2019/11/22/fashion/watches-secondhand-watchbox-watchfinder.html'>
                            <img className='projectImg' src={`${process.env.PUBLIC_URL}/img/watch.jpg`} alt='watch' />
                        </a>
                        <p className='mt-4'>
                            Mein Masterprojekt wurde bei der Firma CHRONEXT in Köln-Ehrenfeld absolviert. Es wurde
                            ein umfangreiches Webtool im Bereich des Pricing entworfen, entwickelt und in die Prozesse des
                            Unternehmens integriert.
                        </p>
                    </Col>
                    <Col xs={12} lg={4} className='pr-lg-5 mt-5 mt-lg-0'>
                        <a target='_blank' className='projectImg' rel='noopener noreferrer'
                            href='https://www.touristiker-nrw.de/neue-beethoven-app-fuer-bonn-vorgestellt/'>
                            <img className='projectImg' src={`${process.env.PUBLIC_URL}/img/beethoven.jpg`} alt='beethoven' />
                        </a>
                        <p className='mt-4'>
                            Als Thema meiner Bachelorarbeit wurde in Kollaboration mit zwei Kommilitonen
                            eine offizielle Beethoven-App für die Bundesstadt Bonn entwickelt. Sie bietet
                            Funktionen wie eine Schnitzeljagd für Kinder, einen Rundgang und einen Eventkalender
                            für interessierte Touristen.
                        </p>
                    </Col>
                    <Col xs={12} lg={4} className='pr-lg-5 mt-5 mt-lg-0'>
                        <a target='_blank' className='projectImg mb-n4 mb-lg-0' rel='noopener noreferrer'
                            href='https://suennershop.de'>
                            <img className='transparentImg' src={`${process.env.PUBLIC_URL}/img/suenner.png`} alt='Sünner' />
                        </a>
                        <p className='mt-2'>
                            Für die Brauerei Gebr. Sünner & Co. KG wurde der neue Onlineshop konzipiert und entwickelt.
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Projects;