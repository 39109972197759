import React, { Component } from 'react';
import {
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
} from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import MdMail from 'react-ionicons/lib/MdMail';
import MdPhonePortrait from 'react-ionicons/lib/MdPhonePortrait';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    sendMail = () => {
        document.location.href = 'mailto:mail@fabianvieten.de';
    }

    call = () => {
        document.location.href = 'tel:+491777496079'
    }

    render = () => {
        return (
            <Navbar className='header' dark expand='lg'>
                <NavbarBrand tag={Link} to={`${process.env.PUBLIC_URL}`}>
                    Fabian Vieten
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem onClick={this.sendMail}>
                            <MdMail fontSize='35px' color='white' />
                            <span className='ml-2 mr-4'>mail@fabianvieten.de</span>
                        </NavItem>
                        <NavItem onClick={this.call} >
                            <MdPhonePortrait fontSize='33px' color='white' />
                            <span className='ml-2'>0177 / 7496079</span>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default Header;