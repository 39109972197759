import React from 'react';
import './App.css';
import Header from './components/Header';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Home from './views/Home';
import Imprint from './views/Imprint';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Footer from './components/Footer';


function App() {
  return (
    <div className='App'>
      <Router>
        <Header />
        <div className='content'>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/impressum`} component={Imprint} />
            <Route path={`${process.env.PUBLIC_URL}`} component={Home} />
            <Redirect exact path={`${process.env.PUBLIC_URL}/`} to='' />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
