import React, { Component } from 'react';
import Welcome from '../components/Welcome';
import About from '../components/About';
import Projects from '../components/Projects';
import Skills from '../components/Skills';

class Home extends Component {

    render = () => {

        return (
            <div>
                <Welcome />
                <Skills />
                <Projects />
                <About />
            </div>
        )
    }
}

export default Home;