import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class About extends Component {

    render = () => {
        return (
            <div className='smalltext smallslide mt-5'>
                <h2>ÜBER MICH</h2>
                <Row className='mt-5'>
                    <Col lg={3}>
                        2020
                   </Col>
                    <Col lg={5}>
                        M. Sc. Informatik
                   </Col>
                    <Col lg={4}>
                        Hochschule Bonn-Rhein-Sieg
                   </Col>
                </Row>
                <Row className='mt-lg-2 mt-5'>
                    <Col lg={3}>
                        2016
                   </Col>
                    <Col lg={5}>
                        B. Sc. Wirtschaftsinformatik
                   </Col>
                    <Col lg={4}>
                        Hochschule Bonn-Rhein-Sieg
                   </Col>
                </Row>
                <Row className='mt-lg-2 mt-5 mb-5 mb-lg-0'>
                    <Col lg={3}>
                        2013
                   </Col>
                    <Col lg={5}>
                        Abitur
                   </Col>
                    <Col lg={4}>
                        Gymnasium Rodenkirchen
                   </Col>
                </Row>
            </div>
        );
    }
}

export default About;