import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Welcome extends Component {

    render = () => {
        return (
            <div className='slides'>
                <Row className='mt-5'>
                    <Col xs={12} lg={4}>
                        <img className='portrait hcenter' src={`${process.env.PUBLIC_URL}/img/portrait.jpg`} alt='portrait' />
                    </Col>
                    <Col className='vcenter pl-5' xs={12} lg={8}>
                        <div>
                            Hi, ich bin <span className='accent'>Fabian</span>.
                            <br />
                            Fullstack Software Entwickler aus Köln.
                        </div>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Welcome;